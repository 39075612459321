import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme: { colors } }): string => colors.white};
  padding-top: 30px;
`

export const Title = styled.h2`
  min-width: 297px;
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 20px;
  font-weight: bold;
  line-height: normal;
  text-align: center;
  color: ${({ theme: { colors } }): string => colors.primary};
  margin-bottom: 20px;
  margin-top: 35px;
`

export const Text = styled.span`
  min-width: 300px;
  width: 90%;
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  text-align: center;
  color: ${({ theme: { colors } }): string => colors.lightGrey};
  margin-bottom: 15px;
`

export const StepsBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
`

export const StepBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`

export const ButtonContainer = styled.div`
  margin-bottom: 40px;
  width: 100%;
  max-width: 385px;
  display: flex;
  justify-content: center;
`

export const SimpleText = styled.div`
  min-width: 209px;
  max-width: 400px;
  width: 90%;
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 14px;
  line-height: normal;
  text-align: center;
  color: ${({ theme: { colors } }): string => colors.primary};
  padding-bottom: 20px;
`

export const StepText = styled(SimpleText)`
  min-height: 100px;
`

export const StepTitle = styled(SimpleText)`
  font-weight: bold;
`

export const StepImage = styled.div`
  width: 300px;
  justify-content: center;
  display: flex;
  margin-bottom: 11px;
`
