import React, { FC, useState } from "react";

import { HeaderContainer } from "./style/home-header/home-header-style";
import ConfirmAddressModal from "../confirm-address-modal";
import { HeaderProps, Inner } from "./home-header";
import {
  ExtendedGoogleMapsPlace,
  PARIS_COORD,
} from "../../store/address/types";

const CorporateHeader: FC<HeaderProps> = (props) => {
  const [confirmAddressModalIsOpen, openAddressModal] = useState<boolean>(
    false
  );
  const [address, setAddress] = useState<ExtendedGoogleMapsPlace>({
    coordinates: PARIS_COORD,
  });

  return (
    <>
      <ConfirmAddressModal
        open={confirmAddressModalIsOpen}
        closeModal={(): void => openAddressModal(false)}
        initialPlace={{ ...address }}
      />
      <HeaderContainer className="home-header-image-container">
        <Inner
          setAddress={setAddress}
          openAddressModal={openAddressModal}
          corporate
          imageUrl={"https://res.cloudinary.com/dnkiryyzd/image/upload/f_auto,w_1920,c_scale/v1603097608/contentful/wast.fr/wast-corporate_qzuimp.jpg"}
          {...props}
        />
      </HeaderContainer>
    </>
  );
};

export default CorporateHeader;
