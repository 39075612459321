import styled from 'styled-components';

export const PageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme: { colors } }): string => colors.grey};
`

export const BodyContainer = styled.div`
  width: 90%;
  max-width: 650px;
  font-family: ${({ theme: { fonts } }): string => fonts.main };
`;

export const OtherLinksContainer = styled.div`
  background-color: ${({ theme: { colors } }): string => colors.grey};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 30px;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`

export const LinksBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 22px;
  align-items: center;
`

export const LinksListContainer = styled.div`
  margin-top: 15px;
`

export const LinksList = styled.ul`
  margin-left: 0;
`

export const Text = styled.span`
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 14px;
  line-height: normal;
  text-align: center;
  color: ${({ theme: { colors } }): string => colors.primary};
`

export const Title = styled(Text)`
  font-weight: bold;
  font-size: 18px;
`
