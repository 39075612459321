import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme: { colors } }): string => colors.grey};
  padding-top: 20px;
`

export const Title = styled.h2`
  min-width: 297px;
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
  text-align: center;
  color: ${({ theme: { colors } }): string => colors.primary};
  margin-bottom: 20px;
  margin-top: 35px;
  width: 90%;
`

export const Text = styled.span`
  min-width: 300px;
  width: 90%;
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  text-align: center;
  color: ${({ theme: { colors } }): string => colors.lightGrey};
  margin-bottom: 15px;
`

export const ButtonContainer = styled.div`
  margin-bottom: 40px;
  width: 100%;
  max-width: 385px;
  display: flex;
  justify-content: center;
`
