import React, { FC } from "react"
import { FormattedMessage, useIntl } from 'react-intl'
import { Container, Title, Text, ButtonContainer } from '../style/contact/contact'
import ButtonLink from "../../../ui/button-link";
import getAppLink from '../../../utils/getAppLink';

const HomeServices: FC = () => {
  const intl = useIntl();

  return (
    <Container>
      <Title><FormattedMessage id="seo.contact.title" /></Title>
      <Text><FormattedMessage id="seo.contact.text" /></Text>
      <ButtonContainer>
        <ButtonLink link={getAppLink()} text={intl.formatMessage({ id: "seo.contact.linkButton" })} borderStyle="30px" width="90%" />
      </ButtonContainer>
    </Container>
  );
}

export default HomeServices
