import React, { FC } from "react"
import { FormattedMessage } from 'react-intl'
import { Container, StepBlock, StepsBlock, StepTitle, StepText, StepImage } from '../style/seo-description/seo-description-container'
import Flexible from '../../../images/Flexible.svg'
import Easy from '../../../images/Easy.svg'
import Shirt from '../../../images/Business.svg'

const HomeServices: FC = () => (
  <Container>
    <StepsBlock>
      <StepBlock>
        <StepImage>
          <Flexible />
        </StepImage>
        <StepTitle>
          <FormattedMessage id="seo.description.flexible" />
        </StepTitle>
        <StepText>
          <FormattedMessage id="seo.description.flexible.text" />
        </StepText>
      </StepBlock>
      <StepBlock>
        <StepImage>
          <Easy />
        </StepImage>
        <StepTitle>
          <FormattedMessage id="seo.description.pro" />
        </StepTitle>
        <StepText>
          <FormattedMessage id="seo.description.pro.text" />
        </StepText>
      </StepBlock>
      <StepBlock>
        <StepImage>
          <Shirt />
        </StepImage>
        <StepTitle>
          <FormattedMessage id="seo.description.prices" />
        </StepTitle>
        <StepText>
          <FormattedMessage id="seo.description.prices.text" />
        </StepText>
      </StepBlock>
    </StepsBlock>
  </Container>
)

export default HomeServices
