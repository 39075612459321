import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme: { colors } }): string => colors.grey};
  padding: 10px 0;
  width: 90%;
`

export const TextAndPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 33%;
`

export const Text = styled.span`
font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  color: ${({ theme: { colors } }): string => colors.primary};
`

export const Price = styled(Text)`
  font-weight: bold;
  color: ${({ theme: { colors } }): string => colors.secondary};
  padding-top: 5px;
`
