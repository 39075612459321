import styled from 'styled-components';

export const SmallContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme: { colors } }): string => colors.grey};
  padding-bottom: 20px;
`

export const SmallTitle = styled.h2`
  min-width: 297px;
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  text-align: center;
  color: ${({ theme: { colors } }): string => colors.primary};
  margin-bottom: 10px;
  margin-top: 35px;
  width: 90%;
`

export const SmallText = styled.span`
  min-width: 300px;
  width: 90%;
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  text-align: center;
  color: ${({ theme: { colors } }): string => colors.secondary};
  margin-bottom: 5px;
  text-decoration: none;
`

export const InputsContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme: { colors } }): string => colors.grey};
  padding: 20px 0;
  margin-bottom: 0;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    width: 50%;
  }
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 600px;
  margin-bottom: 25px;
  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    padding-right: 10%;
  }
`

export const Label = styled.span`
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 16px;
  font-weight: bold;
  line-height: normal;
  color: ${({ theme: { colors } }): string => colors.primary};
  margin-bottom: 12px;
`

export const Input = styled.input`
  height: 48px;
  padding: 0 15px;
  border-radius: 10px;
  border: none;
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  color: ${({ theme: { colors } }): string => colors.primary};
  font-size: 16px;
`

export const LongInput = styled.textarea`
  height: 135px;
  padding: 15px;
  border-radius: 10px;
  border: none;
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  color: ${({ theme: { colors } }): string => colors.primary};
  font-size: 16px;
`

export const Button = styled.button`
  height: 48px;
  object-fit: contain;
  border-radius: 57px;
  width: 90%;
  color: ${({ theme: { colors } }): string => colors.white};
  background-color: ${({ theme: { colors } }): string => colors.secondary};
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  border: none;
`

export const Wrapper = styled.div`
  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    display: flex;
  }
`;

export const Container = styled.div`
  background-color: ${({ theme: { colors } }): string => colors.grey};
  padding: 0 5%;
  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    display: flex;
    flex-direction: column;
    padding-left: 20%;
    width: 50%;
  }
`;

export const Title = styled.h2`
  min-width: 297px;
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
  text-align: center;
  color: ${({ theme: { colors } }): string => colors.primary};
  margin-bottom: 20px;
  padding-top: 35px;
  width: 100%;
`;

export const Text = styled.span`
  min-width: 300px;
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  text-align: center;
  color: ${({ theme: { colors } }): string => colors.lightGrey};
  margin-bottom: 15px;
  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    width: 100%;
  }
`
