import React, { FC } from "react";
import { Container, BreadcrumbContainer, BreadcrumbBlock, Text, BoldText } from '../style/breadcrumb/breadcrumb';
import { Link } from "gatsby";
import {AllContentfulPages, ContentfulNode} from "../../../../types/contentful";

interface Props {
  breadcrumbTitle: string | null;
  parentSlug?: string | null;
  allContentfulPages: AllContentfulPages;
}

const HomeServices: FC<Props> = ({ breadcrumbTitle, parentSlug, allContentfulPages }) => {
  let previousPages: ContentfulNode[] = [];
  let lastPage: ContentfulNode | null = null;

  allContentfulPages.edges.forEach((page) => {
    if (page.node.slug === parentSlug) {
      previousPages.push(page.node);
      lastPage = page.node;
    }
  });

  if (lastPage && (lastPage as ContentfulNode).parentSlug) {
    allContentfulPages.edges.forEach((page) => {
      if (page.node.slug === (lastPage as ContentfulNode).parentSlug) {
        previousPages.push(page.node);
      }
    });
  }

  previousPages = previousPages.reverse();

  return (
    <Container>
      <BreadcrumbContainer>
        <BreadcrumbBlock>
          <Link to="/" style={{'textDecoration': 'none'}}><Text>Wast &gt; </Text></Link>
          {previousPages.map((page) => (
            <Link key={page.slug} to={page.slug} style={{'textDecoration': 'none'}}><Text>{`${page.breadcrumbTitle} > `}</Text></Link>
          ))}
          <BoldText>{breadcrumbTitle}</BoldText>
        </BreadcrumbBlock>
      </BreadcrumbContainer>
    </Container>
  );
}

export default HomeServices
