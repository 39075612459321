import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme: { colors } }): string => colors.grey};
  padding-top: 30px;
`

export const StepBlock = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme: { colors } }): string => colors.grey};
  border-radius: 10px;
  align-items: center;
  margin-bottom: 30px;
`

export const StepsBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
`

export const StepText = styled.div`
  width: 209px;
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 14px;
  line-height: normal;
  text-align: center;
  color: ${({ theme: { colors } }): string => colors.primary};
  padding-bottom: 20px;
`

export const StepTitle = styled(StepText)`
  font-weight: bold;
`

export const StepImage = styled.div`
  width: 300px;
  justify-content: center;
  display: flex;
  margin-bottom: 11px;
`
