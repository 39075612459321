import React, { FC } from "react";
import { FormattedMessage } from 'react-intl';
import { StepBlock, StepsBlock, StepTitle, StepText, StepImage } from '../../seo/style/seo-description/seo-description-container';
import { Container } from './style';
import DeliveryTruck from '../../../images/Delivery-truck.svg';
import Easy from '../../../images/Easy.svg';
import Shirt from '../../../images/Business.svg';

const HomeServices: FC = () => (
  <Container>
    <StepsBlock>
      <StepBlock>
        <StepImage>
          <Easy />
        </StepImage>
        <StepTitle>
          <FormattedMessage id="company.description.convenient" />
        </StepTitle>
        <StepText>
          <FormattedMessage id="company.description.convenient.text" />
        </StepText>
      </StepBlock>
      <StepBlock>
        <StepImage>
          <Shirt />
        </StepImage>
        <StepTitle>
          <FormattedMessage id="company.description.pro" />
        </StepTitle>
        <StepText>
          <FormattedMessage id="company.description.pro.text" />
        </StepText>
      </StepBlock>
      <StepBlock>
        <StepImage>
          <DeliveryTruck />
        </StepImage>
        <StepTitle>
          <FormattedMessage id="company.description.efficient" />
        </StepTitle>
        <StepText>
          <FormattedMessage id="company.description.efficient.text" />
        </StepText>
      </StepBlock>
    </StepsBlock>
  </Container>
)

export default HomeServices
