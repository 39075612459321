/* eslint-disable @typescript-eslint/camelcase */
import React, { FC, useEffect } from "react"
import Helmet from "react-helmet";
import { FormattedMessage, useIntl } from 'react-intl'
import ContactForm from "../../contact-form";
import {
  Container,
  SmallContainer,
  SmallTitle,
  SmallText,
  Title,
  Text,
  Wrapper
} from '../style/contact/contact'

const HomeServices: FC = () => {
  const intl = useIntl();

  return (
    <Wrapper>
      <Helmet>
        <script type="text/javascript">
          {`function gtag_report_conversion(url) {
            const callback = function () {
              if (typeof(url) != 'undefined') {
                window.location = url;
              }
            };
            gtag('event', 'conversion', {
                'send_to': 'AW-826522137/aCJwCPWs1pQDEJn0jooD',
                'event_callback': callback
            });
            return false;
          }`}
        </script>
      </Helmet>
      <Container id="form">
        <Title><FormattedMessage id="company.contact.title" /></Title>
        <Text><FormattedMessage id="company.contact.text" /></Text>
        <SmallContainer>
          <SmallTitle><FormattedMessage id="company.contact.contactInformation" /></SmallTitle>
          <SmallText><FormattedMessage id="company.contact.contactInformation.tel" /></SmallText>
          <a href={`mailto:${intl.formatMessage({ id: "company.contact.contactInformation.email" })}`}><SmallText><FormattedMessage id="company.contact.contactInformation.email" /></SmallText></a>
        </SmallContainer>
      </Container>
      <iframe frameBorder="0" style={{height: "720px", width:'99%', border:'none'}} src='https://forms.zohopublic.eu/washfast/form/B2Bwastfr1/formperma/LL5c9DvTV9A_m3WYTUHrV2IzKh9QkBNloXdzcjAtDyQ'></iframe>
      {// TODO: Uncomment to use Zoho Form with gtag function for quotes
      /* <ContactForm /> */
      }
    </Wrapper>
  );
}

export default HomeServices
