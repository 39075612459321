import React, { FC } from "react"
import { FormattedMessage } from 'react-intl'
import { Container, Text, Price, TextAndPriceContainer } from '../style/seo-prices/triple-price'

const HomeServices: FC = () => (
  <Container>
    <TextAndPriceContainer>
      <Text><FormattedMessage id="seo.prices.tripePrice.shirt.text" /></Text>
      <Price><FormattedMessage id="seo.prices.tripePrice.shirt.price" /></Price>
    </TextAndPriceContainer>
    <TextAndPriceContainer>
      <Text><FormattedMessage id="seo.prices.tripePrice.shirtHanger.text" /></Text>
      <Price><FormattedMessage id="seo.prices.tripePrice.shirtHanger.price" /></Price>
    </TextAndPriceContainer>
    <TextAndPriceContainer>
      <Text><FormattedMessage id="seo.prices.tripePrice.suit.text" /></Text>
      <Price><FormattedMessage id="seo.prices.tripePrice.suit.price" /></Price>
    </TextAndPriceContainer>
  </Container>
)

export default HomeServices
