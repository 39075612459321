import React, { FC } from "react"
import { FormattedMessage } from 'react-intl'
import { Title, Container } from '../../home/style/4-steps/4-steps-container'

const HomeServices: FC = () => (
  <Container className="several-steps-container">
    <Title><FormattedMessage id="company.steps.title" /></Title>
  </Container>
)

export default HomeServices
