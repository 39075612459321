import React, { FC } from "react";
import { FormattedMessage, useIntl } from 'react-intl';
import { Title, Text, Container, StepBlock,StepsBlock, StepTitle, StepText, StepImage, ButtonContainer } from '../style/seo-prices/seo-prices-container';
import SimplePrice from './simple-price';
import TriplePrice from './triple-price';
import CleaningBag from '../../images/cleaning-bag';
import PressingShirt from '../../images/pressing-shirt';
import ButtonLink from "../../../ui/button-link";
import style from '../style/images.css';

const HomeServices: FC = () => {
  const intl = useIntl();

  return (
    <Container className="seo-prices-container">
      <Title><FormattedMessage id="seo.prices.title" /></Title>
      <Text><FormattedMessage id="seo.prices.text" /></Text>
      <StepsBlock>
        <StepBlock className={style.image}>
          <StepImage>
            <CleaningBag />
          </StepImage>
          <StepTitle>
            <FormattedMessage id="seo.prices.cleaned" />
          </StepTitle>
          <StepText>
            <FormattedMessage id="seo.prices.cleaned.text" />
          </StepText>
          <SimplePrice />
        </StepBlock>
        <StepBlock>
          <StepImage>
            <PressingShirt />
          </StepImage>
          <StepTitle>
            <FormattedMessage id="seo.prices.pressing" />
          </StepTitle>
          <StepText>
            <FormattedMessage id="seo.prices.pressing.text" />
          </StepText>
          <TriplePrice />
        </StepBlock>
      </StepsBlock>
      <ButtonContainer>
        <ButtonLink link="/pricing" text={intl.formatMessage({ id: "seo.prices.button" })} borderStyle="30px" width="90%" />
      </ButtonContainer>
    </Container>
  );
}

export default HomeServices
