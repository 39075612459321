import React, { FC } from "react"
import { graphql, Link } from "gatsby"
import SEO from '../components/seo';
import Layout from "../components/layout";
import { ContentfulGraphQLResponse } from '../../types/contentful';
import ReactMarkdown from 'react-markdown';
import { FormattedMessage } from 'react-intl';
import { BodyContainer, PageContainer, LinksListContainer, LinksList, Title, OtherLinksContainer, LinksBlock } from './style/page-content';
import style from './style/body.css';

import HomeHeader from "../components/home/home-header"
import CorporateHeader from "../components/home/corporate-header"
import Home4Steps from "../components/home/4-steps"
import SeoDescription from "../components/seo/seo-description"
import SeoPrices from "../components/seo/seo-prices"
import Contact from "../components/seo/contact"
import Breadcrumb from "../components/seo/breadcrumb"

import Home3Steps from "../components/company/3-steps"
import CompanyDescription from "../components/company/company-description"
import CorporateContact from "../components/company/contact"

const PagesContent: FC<ContentfulGraphQLResponse> = ({ data }) => {
  if (!data) {
    return null;
  }

  const {
    contentfulPages: {
      descriptionMetadata,
      titleMetadata,
      childContentfulPagesBodyTextNode: { body },
      type,
      title: pageTitle,
      slug,
      breadcrumbTitle,
      parentSlug,
    },
    allContentfulPages,
  } = data;

  return (
    <Layout header={null === type} menuState={'corporate' === type ? 'factory' : null}>
      <>
        <SEO title={titleMetadata} description={descriptionMetadata} />
        {'corporate' === type && (
          <>
            <CorporateHeader
              home={false}
              services={['company.services.text']}
              button
              breadcrumb={<Breadcrumb breadcrumbTitle={breadcrumbTitle} allContentfulPages={allContentfulPages} />}
            />
            <Home3Steps />
            <CompanyDescription />
            <CorporateContact />
          </>
        )}
        {'corporate' !== type && (
          <>
            <HomeHeader
              breadcrumb={<Breadcrumb breadcrumbTitle={breadcrumbTitle} allContentfulPages={allContentfulPages} parentSlug={parentSlug} />}
              home={false}
              title={pageTitle}
              services={['seo.city.shipping', 'seo.city.discount', 'seo.city.shirt']}
            />
            <SeoDescription />
            <Home4Steps />
            <PageContainer className={style.page}>
              <BodyContainer className="body-container">
                <ReactMarkdown source={body} />
              </BodyContainer>
            </PageContainer>
            <SeoPrices />
            <OtherLinksContainer className="other-links-container">
              <LinksBlock>
                {'city' === type && (
                  <Title><FormattedMessage id="seo.otherLinks.districts" /></Title>
                )}
                {'district' === type && (
                  <Title><FormattedMessage id={slug.includes('paris') ? "seo.otherLinks.districtsInParis" : "seo.otherLinks.districtsInCity"} /></Title>
                )}
                {'services' === type && (
                  <Title><FormattedMessage id="seo.otherLinks.serviceCities" /></Title>
                )}
                <LinksListContainer>
                  <LinksList>
                    {
                      allContentfulPages.edges.map(({ node: { slug: nodeSlug, title: nodeTitle, type: nodeType, parentSlug: nodeParentSlug } }) => {
                        const title = nodeTitle || nodeSlug;

                        if ('city' === type && 'district' === nodeType && nodeParentSlug === slug) {
                          return (
                            <li className="label__grid--three" key={nodeSlug}>
                              <Link className="labelCard" to={`/${nodeSlug}`}>
                                <h3>{title}</h3>
                              </Link>
                            </li>
                          )
                        }

                        if ('district' === type && nodeParentSlug === parentSlug) {
                          return (
                            <li className="label__grid--three" key={nodeSlug}>
                              <Link className="labelCard" to={`/${nodeSlug}`}>
                                <h3>{title}</h3>
                              </Link>
                            </li>
                          )
                        }

                        if ('services' === type && nodeParentSlug === slug) {
                          return (
                            <li className="label__grid--three" key={nodeSlug}>
                              <Link className="labelCard" to={`/${nodeSlug}`}>
                                <h3>{title}</h3>
                              </Link>
                            </li>
                          )
                        }
                      })
                    }
                  </LinksList>
                </LinksListContainer>
              </LinksBlock>
              {'city' === type && (
                <LinksBlock>
                  <Title><FormattedMessage id="seo.otherLinks.cities" /></Title>
                  <LinksListContainer>
                    <LinksList>
                      {
                        allContentfulPages.edges.map(({ node: { slug: nodeSlug, title: nodeTitle, type: nodeType } }) => {
                          const title = nodeTitle || nodeSlug;

                          if ('city' === nodeType && nodeSlug !== slug) {
                            return (
                              <li className="label__grid--three" key={nodeSlug}>
                                <Link className="labelCard" to={`/${nodeSlug}`}>
                                  <h3>{title}</h3>
                                </Link>
                              </li>
                            )
                          }
                        })
                      }
                    </LinksList>
                  </LinksListContainer>
                </LinksBlock>
              )}
            </OtherLinksContainer>
            <Contact />
          </>
        )}
      </>
    </Layout>
  )
}

export default PagesContent
export const pageQuery = graphql`
  query PagesBySlug ($slug: String!, $urlSlug: String!){
    site {
      siteMetadata {
        title
      }
    }
    contentfulPages (slug : {eq : $slug}){
      title
      slug
      createdAt
      childContentfulPagesBodyTextNode{
        body
      }
      titleMetadata
      descriptionMetadata
      type
      breadcrumbTitle
      parentSlug
    }
    allContentfulPages (filter : {slug: { regex: $urlSlug }},
    sort : {fields : title, order:ASC}) {
      edges {
        node {
          title
          slug
          parentSlug
          category
          type
          breadcrumbTitle
          imageLabel {
            title
            fluid {
              src
            }
          }
        }
      }
    }
  }
`
