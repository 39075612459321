import React, { FC } from "react"
import { FormattedMessage } from 'react-intl'
import { Container, Text, Price } from '../style/seo-prices/simple-price'

const HomeServices: FC = () => (
  <Container>
    <Text><FormattedMessage id="seo.prices.simplePrice.text" /></Text>
    <Price><FormattedMessage id="seo.prices.simplePrice.price" /></Price>
  </Container>
)

export default HomeServices
