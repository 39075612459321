import styled from 'styled-components';

export const Container = styled.div`
  height: 55px;
  display: flex;
  justify-content: center;
`

export const BreadcrumbContainer = styled.div`
  width: 90%;
  display: flex;
`

export const BreadcrumbBlock = styled.div`
  height: 30px;
  border-radius: 13px;
  background-color: ${({ theme: { colors } }): string => colors.white};
  padding: 0 15px;

  @media (max-width: 481px) {
    height: 25px;
  }
`

  export const Text = styled.span`
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 10px;
  font-weight: 500;
  line-height: normal;
  color: ${({ theme: { colors } }): string => colors.primary};
  padding: 0 1px;
  text-decoration: none;
`

export const BoldText = styled.span`
font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 10px;
  line-height: normal;
  color: ${({ theme: { colors } }): string => colors.primary};
  font-weight: bold;
`
